@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h2 {
        @apply text-orange;
        @apply text-2xl;
        @apply py-3;
        @apply font-bold;
        @apply underline;
    }

    h3 {
        @apply text-orange;
    }

    ul {
        @apply my-4;
        @apply leading-loose;
    }

}